import React, { useCallback, useEffect, useState } from "react";
import {
  Flex,
  Button,
  Box,
  Card,
  Heading,
  Spinner,
  Image,
  Text,
} from "theme-ui";
import {
  LiquityStoreState,
  Decimal,
  Trove,
  LUSD_LIQUIDATION_RESERVE,
  LUSD_MINIMUM_NET_DEBT,
  Percent,
} from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import MintLogo from "../../assets/mint-icon.svg";
import MintBlue from "../../assets/mint-blue.svg";
import WalletBlue from "../../assets/wallet-blue.svg";

import { useStableTroveChange } from "../../hooks/useStableTroveChange";
import { ActionDescription } from "../ActionDescription";
import { useMyTransactionState } from "../Transaction";
import { TroveAction } from "./TroveAction";
import { useTroveView } from "./context/TroveViewContext";
import { COIN } from "../../strings";
import { Icon } from "../Icon";
import { InfoIcon } from "../InfoIcon";
import { LoadingOverlay } from "../LoadingOverlay";
import { CollateralRatio } from "./CollateralRatio";
import { EditableRow, StaticRow } from "./Editor";
import {
  ExpensiveTroveChangeWarning,
  GasEstimationState,
} from "./ExpensiveTroveChangeWarning";
import {
  selectForTroveChangeValidation,
  validateTroveChange,
} from "./validation/validateTroveChange";
import Tabs from "../Tabs";

const selector = (state: LiquityStoreState) => {
  const { fees, price, accountBalance, lusdBalance } = state;
  return {
    fees,
    price,
    accountBalance,
    lusdBalance,
    validationContext: selectForTroveChangeValidation(state),
  };
};

const EMPTY_TROVE = new Trove(Decimal.ZERO, Decimal.ZERO);
const TRANSACTION_ID = "trove-creation";
const GAS_ROOM_ETH = Decimal.from(0.1);
type OpeningProps = {
  currentTab: string;
  tabs: string[];
  setCurrentTab: (value: string) => void;
};

export const Opening: React.FC<OpeningProps> = ({
  tabs,
  currentTab,
  setCurrentTab,
}) => {
  const { dispatchEvent } = useTroveView();
  const {
    fees,
    price,
    accountBalance,
    validationContext,
    lusdBalance,
  } = useLiquitySelector(selector);
  const borrowingRate = fees.borrowingRate();
  const editingState = useState<string>();

  const [collateral, setCollateral] = useState<Decimal>(Decimal.ZERO);
  const [borrowAmount, setBorrowAmount] = useState<Decimal>(Decimal.ZERO);

  const maxBorrowingRate = borrowingRate.add(0.005);

  const fee = borrowAmount.mul(borrowingRate);
  const feePct = new Percent(borrowingRate);
  const totalDebt = borrowAmount.add(LUSD_LIQUIDATION_RESERVE).add(fee);
  const isDirty = !collateral.isZero || !borrowAmount.isZero;
  const trove = isDirty ? new Trove(collateral, totalDebt) : EMPTY_TROVE;
  const maxCollateral = accountBalance.gt(GAS_ROOM_ETH)
    ? accountBalance.sub(GAS_ROOM_ETH)
    : Decimal.ZERO;
  const collateralMaxedOut = collateral.eq(maxCollateral);
  const collateralRatio =
    !collateral.isZero && !borrowAmount.isZero
      ? trove.collateralRatio(price)
      : undefined;

  const [troveChange, description] = validateTroveChange(
    EMPTY_TROVE,
    trove,
    borrowingRate,
    validationContext,
    false
  );

  const stableTroveChange = useStableTroveChange(troveChange);
  const [
    gasEstimationState,
    setGasEstimationState,
  ] = useState<GasEstimationState>({ type: "idle" });

  const transactionState = useMyTransactionState(TRANSACTION_ID);
  const isTransactionPending =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  const handleCancelPressed = useCallback(() => {
    dispatchEvent("CANCEL_ADJUST_TROVE_PRESSED");
  }, [dispatchEvent]);

  const reset = useCallback(() => {
    setCollateral(Decimal.ZERO);
    setBorrowAmount(Decimal.ZERO);
  }, []);

  useEffect(() => {
    if (!collateral.isZero && borrowAmount.isZero) {
      setBorrowAmount(LUSD_MINIMUM_NET_DEBT);
    }
  }, [collateral, borrowAmount]);

  useEffect(() => {
    reset();
  }, [currentTab]);

  return (
    <Card>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          pb: 3,
          borderBottom: 1,
          borderColor: "#263340",
        }}
      >
        <Flex sx={{ alignItems: "center", gap: 2 }}>
          <Image src={MintLogo} />
          <Heading sx={{}}>Mint USDEFI</Heading>
        </Flex>
        {isDirty && !isTransactionPending && (
          <Button
            variant="titleIcon"
            sx={{ ":enabled:hover": { color: "danger" } }}
            onClick={reset}
          >
            <Icon name="history" size="lg" />
          </Button>
        )}
      </Flex>

      <Box sx={{ py: [2, 3], px: [0, 0] }}>
        <Flex
          sx={{
            justifyContent: "space-between",
            width: "100%",
            px: "38px",
            mb: "6px",
          }}
        >
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            disabledTab={tabs[1]}
          />
          <Flex sx={{ color: "#0C94C0", alignItems: "center", gap: 1 }}>
            <Image src={MintBlue} width={"15px"} height={"auto"} />
            <Text sx={{ fontSize: "14px" }}>{trove.collateral.prettify()}</Text>
          </Flex>
        </Flex>
        <EditableRow
          label={
            currentTab === tabs[0] ? "Add Collateral" : "Remove Collateral"
          }
          inputId="trove-collateral"
          amount={collateral.prettify(4)}
          maxAmount={maxCollateral.toString(2)}
          maxedOut={collateralMaxedOut}
          editingState={editingState}
          unit="BNB"
          editedAmount={collateral.toString(4)}
          setEditedAmount={(amount: string) => {
            const decimalPlaces = amount.split(".")[1]?.length || 0;
            if (decimalPlaces <= 4) {
              setCollateral(Decimal.from(amount));
            } else {
              console.log("Cannot Enter More than 4 Decimals");
            }
          }}
        />
        <Flex
          sx={{
            justifyContent: "space-between",
            width: "100%",
            px: "38px",
            mb: "6px",
          }}
        >
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            secondary
            disabledTab={tabs[1]}
          />
          <Flex sx={{ color: "#0C94C0", alignItems: "center", gap: 1 }}>
            <Image src={WalletBlue} width={"15px"} height={"auto"} />
            {([
              [COIN, Decimal.from(lusdBalance || 0), "#0C94C0"],

              // ["bLUSD", Decimal.from(bLusdBalance || 0)]
            ] as const).map(([currency, balance, color], i) => (
              <Text key={i} sx={{ fontSize: "14px", color: color }}>
                {balance.prettify()}
              </Text>
            ))}
          </Flex>
        </Flex>
        <EditableRow
          label={currentTab === tabs[0] ? "Borrow" : "Repay"}
          inputId="trove-borrow-amount"
          amount={borrowAmount.prettify()}
          unit={COIN}
          editingState={editingState}
          editedAmount={borrowAmount.toString(2)}
          setEditedAmount={(amount: string) => {
            const decimalPlaces = amount.split(".")[1]?.length || 0;
            if (decimalPlaces <= 2) {
              setBorrowAmount(Decimal.from(amount));
            } else {
              console.log("Cannot Enter More than 2 Decimals");
            }
          }}
        />

        <StaticRow
          label="Liquidation Reserve"
          inputId="trove-liquidation-reserve"
          amount={`${LUSD_LIQUIDATION_RESERVE}`}
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "200px" }}>
                  An amount set aside to cover the liquidator’s gas costs if
                  your MINT needs to be liquidated. The amount increases your
                  debt and is refunded if you close your MINT by fully paying
                  off its net debt.
                </Card>
              }
            />
          }
        />

        <StaticRow
          label="Borrowing Fee"
          inputId="trove-borrowing-fee"
          amount={fee.prettify(2)}
          pendingAmount={feePct.toString(2)}
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "240px" }}>
                  This amount is deducted from the borrowed amount as a one-time
                  fee. There are no recurring fees for borrowing, which is thus
                  interest-free.
                </Card>
              }
            />
          }
        />

        <StaticRow
          label="Total debt"
          inputId="trove-total-debt"
          amount={totalDebt.prettify(2)}
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "240px" }}>
                  The total amount of USDEFI your MINT will hold.{" "}
                  {isDirty && (
                    <>
                      You will need to repay{" "}
                      {totalDebt.sub(LUSD_LIQUIDATION_RESERVE).prettify(2)}{" "}
                      USDEFI to reclaim your collateral (
                      {LUSD_LIQUIDATION_RESERVE.toString()} USDEFI Liquidation
                      Reserve excluded).
                    </>
                  )}
                </Card>
              }
            />
          }
        />

        <CollateralRatio value={collateralRatio} />

        {description ?? (
          <ActionDescription>
            Start by entering the amount of BNB you'd like to deposit as
            collateral.
          </ActionDescription>
        )}

        <ExpensiveTroveChangeWarning
          troveChange={stableTroveChange}
          maxBorrowingRate={maxBorrowingRate}
          borrowingFeeDecayToleranceMinutes={60}
          gasEstimationState={gasEstimationState}
          setGasEstimationState={setGasEstimationState}
        />

        <Flex variant="layout.actions">
          <Button variant="cancel" onClick={handleCancelPressed}>
            Cancel
          </Button>

          {gasEstimationState.type === "inProgress" ? (
            <Button disabled>
              <Spinner size="24px" sx={{ color: "background" }} />
            </Button>
          ) : stableTroveChange ? (
            <TroveAction
              transactionId={TRANSACTION_ID}
              change={stableTroveChange}
              maxBorrowingRate={maxBorrowingRate}
              borrowingFeeDecayToleranceMinutes={60}
            >
              Confirm
            </TroveAction>
          ) : (
            <Button disabled>Confirm</Button>
          )}
        </Flex>
      </Box>
      {isTransactionPending && <LoadingOverlay />}
    </Card>
  );
};
