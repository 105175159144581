import React, { useEffect, useState } from "react";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { Container, Flex, Box } from "theme-ui";
import { AddressZero } from "@ethersproject/constants";
import { useLiquity } from "../hooks/LiquityContext";

import { LiquityLogo } from "./LiquityLogo";
import { Nav } from "./Nav";
import { SideNav } from "./SideNav";

const logoHeight = "35px";

const select = ({ frontend }: LiquityStoreState) => ({
  frontend
});

export const Header: React.FC = ({ children }) => {
  const {
    config: { frontendTag }
  } = useLiquity();
  const { frontend } = useLiquitySelector(select);
  const isFrontendRegistered = frontendTag === AddressZero || frontend.status === "registered";
  const [scrollThreshold, setScrollThreshold] = useState(window.scrollY);
  useEffect(() => {
    // const handleWindowResize = () => setWidth(window.innerWidth);
    // window.addEventListener("resize", handleWindowResize);
    const throttledListener = () => setScrollThreshold(window.scrollY);
    window.addEventListener("scroll", throttledListener);
    document
      .getElementById("DecentralPage")!
      .addEventListener("scroll", (scroll) => {
        setScrollThreshold((scroll.target! as HTMLElement).scrollTop);
      });

    return () => {
      // window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("scroll", throttledListener);
    };
  }, []);

  return (
    <Container variant="header" sx={{
      background: ["#162029", scrollThreshold > 50 ? 'none' : "#131B23"],
      backdropFilter:scrollThreshold > 50?'blur(60px)':"none",
    }}>
      <Flex sx={{ flexDirection:['column','row'],justifyContent:['center','space-between'],alignItems:'center',width:'100%' ,gap:[4,0],mt:['40px','10px']}}>
      <Flex sx={{ alignItems: "center",justifyContent:'space-between',width:['100%','auto'] }}>
          <LiquityLogo height={logoHeight} sx={{display:['none','flex'],mr:4}} />

        {/* <Box
          sx={{
            mx: [2, 3],
            width: "0px",
            height: "100%",
            borderLeft: ["none", "none"]
          }}
        /> */}
        {isFrontendRegistered && (
          <>
            <SideNav />
            <Nav scrollThreshold={scrollThreshold} />
          </>
        )}
      </Flex>

        {children}
        </Flex>
    </Container>
  );
};
