import React, { useState } from "react";
import { StabilityDepositManager } from "./StabilityDepositManager";
import { ActiveDeposit } from "./ActiveDeposit";
import { NoDeposit } from "./NoDeposit";
import { useStabilityView } from "./context/StabilityViewContext";

export const Stability: React.FC = (props) => {
  const { view } = useStabilityView();
  const tabs = ["Deposit USDEFI", "Withdraw USDEFI"];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  switch (view) {
    case "NONE": {
      return <NoDeposit {...props} />;
    }
    case "DEPOSITING": {
      return (
        <StabilityDepositManager
          {...props}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
    case "ADJUSTING": {
      return (
        <StabilityDepositManager
          {...props}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
    case "ACTIVE": {
      // return <ActiveDeposit {...props} />;
      return (
        <StabilityDepositManager
          {...props}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
  }
};
