import React, { useState } from "react";
import { Flex, Box, Text, ThemeUIStyleObject } from "theme-ui";

type TabsProps = {
  tabs: string[];
  currentTab: string;
  disabledTab?: string;
  secondary?: boolean;
  setCurrentTab: (value: string) => void;
};

const tabStyle: ThemeUIStyleObject = {
  width: "100%",
  cursor: "pointer",
  // px: 2,
  transition: "border-color 0.3s",
  fontSize: ["14px", null, null, "20px"],
  // mb: "5px",
};

const Tabs: React.FC<TabsProps> = ({
  tabs,
  currentTab,
  setCurrentTab,
  disabledTab,
  secondary,
}) => {
  const handleClick = (tab: string) => {
    setCurrentTab(tab);
  };
  const secondaryTabs = ["Borrow USDEFI", "Repay USDEFI"];

  return (
    <Flex sx={{ gap: 2, justifyContent: "flex-start" }}>
      {tabs.map((tab, index) => (
        <Box
          key={index}
          sx={{
            ...tabStyle,
            // fontFamily: "Titillium Web, sans-serif",
            width: "fit-content",

            fontSize: "14px",
            color:
              currentTab === tab
                ? "#0C94C0"
                : disabledTab !== tab
                ? "#869CB2"
                : "rgba(128,128,128,0.2)",
            textDecoration: "none",
            // borderBottom: `2px solid ${
            //   currentTab === tab ? "#C22EFF" : "transparent"
            // }`,
            ":hover": {
              color:
                disabledTab !== tab
                  ? "#0C94C0"
                  : disabledTab !== tab
                  ? "#869CB2"
                  : "rgba(128,128,128,0.2)",
              // borderBottomColor:
              //   disabledTab !== tab ? "#C22EFF" : "transparent",
              // "rgba(128,128,128,0.2)"
            },
          }}
          onClick={() => {
            if (disabledTab !== tab) {
              handleClick(tab);
            }
          }}
        >
          <Text sx={{ m: 0 }}>{!secondary ? tab : secondaryTabs[index]}</Text>
        </Box>
      ))}
    </Flex>
  );
};

export default Tabs;
