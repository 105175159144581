import React, { useEffect } from "react";
import { Button, Flex } from "theme-ui";

import {
  Decimal,
  Decimalish,
  LiquityStoreState,
  LQTYStake,
  LQTYStakeChange,
} from "@liquity/lib-base";

import {
  LiquityStoreUpdate,
  useLiquityReducer,
  useLiquitySelector,
} from "@liquity/lib-react";

import { GT, COIN } from "../../strings";

import { useStakingView } from "./context/StakingViewContext";
import { StakingEditor } from "./StakingEditor";
import { StakingManagerAction } from "./StakingManagerAction";
import { ActionDescription, Amount } from "../ActionDescription";
import { ErrorDescription } from "../ErrorDescription";
import { StakingGainsAction } from "./StakingGainsAction";

const selectLQTYStake = ({ lqtyStake }: LiquityStoreState) => lqtyStake;

const init = ({ lqtyStake }: LiquityStoreState) => ({
  originalStake: lqtyStake,
  editedLQTY: lqtyStake.stakedLQTY,
});

type StakeManagerState = ReturnType<typeof init>;
type StakeManagerAction =
  | LiquityStoreUpdate
  | { type: "revert" }
  | { type: "setStake"; newValue: Decimalish };

const reduce = (
  state: StakeManagerState,
  action: StakeManagerAction
): StakeManagerState => {
  // console.log(state);
  // console.log(action);

  const { originalStake, editedLQTY } = state;

  switch (action.type) {
    case "setStake":
      return { ...state, editedLQTY: Decimal.from(action.newValue) };

    case "revert":
      return { ...state, editedLQTY: originalStake.stakedLQTY };

    case "updateStore": {
      const {
        stateChange: { lqtyStake: updatedStake },
      } = action;

      if (updatedStake) {
        return {
          originalStake: updatedStake,
          editedLQTY: updatedStake.apply(originalStake.whatChanged(editedLQTY)),
        };
      }
    }
  }

  return state;
};

const selectLQTYBalance = ({ lqtyBalance }: LiquityStoreState) => lqtyBalance;

type StakingManagerActionDescriptionProps = {
  originalStake: LQTYStake;
  change: LQTYStakeChange<Decimal>;
};

const StakingManagerActionDescription: React.FC<StakingManagerActionDescriptionProps> = ({
  originalStake,
  change,
}) => {
  const stakeLQTY = change.stakeLQTY?.prettify().concat(" ", GT);
  const unstakeLQTY = change.unstakeLQTY?.prettify().concat(" ", GT);
  const collateralGain = originalStake.collateralGain.nonZero
    ?.prettify(4)
    .concat(" BNB");
  const lusdGain = originalStake.lusdGain.nonZero?.prettify().concat(" ", COIN);

  if (originalStake.isEmpty && stakeLQTY) {
    return (
      <ActionDescription>
        You are staking <Amount>{stakeLQTY}</Amount>.
      </ActionDescription>
    );
  }

  return (
    <ActionDescription>
      {stakeLQTY && (
        <>
          You are adding <Amount>{stakeLQTY}</Amount> to your stake
        </>
      )}
      {unstakeLQTY && (
        <>
          You are withdrawing <Amount>{unstakeLQTY}</Amount> to your wallet
        </>
      )}
      {(collateralGain || lusdGain) && (
        <>
          {" "}
          and claiming{" "}
          {collateralGain && lusdGain ? (
            <>
              <Amount>{collateralGain}</Amount> and <Amount>{lusdGain}</Amount>
            </>
          ) : (
            <>
              <Amount>{collateralGain ?? lusdGain}</Amount>
            </>
          )}
        </>
      )}
      .
    </ActionDescription>
  );
};
type StakingMangerProps = {
  currentTab: string;
  tabs: string[];
  setCurrentTab: (value: string) => void;
};
export const StakingManager: React.FC<StakingMangerProps> = ({
  tabs,
  currentTab,
  setCurrentTab,
}) => {
  const { dispatch: dispatchStakingViewAction, view } = useStakingView();
  const { collateralGain, lusdGain } = useLiquitySelector(selectLQTYStake);

  const [{ originalStake, editedLQTY }, dispatch] = useLiquityReducer(
    reduce,
    init
  );
  const lqtyBalance = useLiquitySelector(selectLQTYBalance);

  const change = originalStake.whatChanged(editedLQTY);
  const [validChange, description] = !change
    ? [undefined, undefined]
    : change.stakeLQTY?.gt(lqtyBalance)
    ? [
        undefined,
        <ErrorDescription>
          The amount you're trying to stake exceeds your balance by{" "}
          <Amount>
            {change.stakeLQTY.sub(lqtyBalance).lte(Decimal.from("0.01"))
              ? "<0.01 "
              : change.stakeLQTY.sub(lqtyBalance).prettify()}{" "}
            {GT}
          </Amount>
          .
        </ErrorDescription>,
      ]
    : [
        change,
        <StakingManagerActionDescription
          originalStake={originalStake}
          change={change}
        />,
      ];

  const makingNewStake = originalStake.isEmpty;

  return (
    <StakingEditor
      title={"DCNX Staking"}
      {...{ originalStake, editedLQTY, dispatch }}
      tabs={tabs}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
    >
      {description ??
        (makingNewStake ? (
          <ActionDescription>
            Enter the amount of {GT} you'd like to stake.
          </ActionDescription>
        ) : (
          <ActionDescription>
            Adjust the {GT} amount to stake or withdraw.
          </ActionDescription>
        ))}

      <Flex variant="layout.actions">
        {view === "ADJUSTING" && (
          <Button
            variant="cancel"
            onClick={() =>
              dispatchStakingViewAction({ type: "cancelAdjusting" })
            }
          >
            Cancel
          </Button>
        )}
        <StakingGainsAction />
        {validChange ? (
          <StakingManagerAction change={validChange}>
            Confirm
          </StakingManagerAction>
        ) : (
          <Button disabled>Confirm</Button>
        )}
      </Flex>
    </StakingEditor>
  );
};
