import React from 'react';
import { Avatar } from 'theme-ui';

interface TokenProps {
  tokenName: string;
}

export const Token: React.FC<TokenProps> = ({ tokenName }) => {
  const imagePath = `../../../tokens/${tokenName}.png`;
  console.log(imagePath)

  return <Avatar src={imagePath} alt={tokenName} />;
};

