import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button, Image, Link } from "theme-ui";
import { InfoMessage } from "../InfoMessage";
import { useTroveView } from "./context/TroveViewContext";
import MintLogo from "../../assets/mint-icon.svg";

export const NoTrove: React.FC = (props) => {
  const { dispatchEvent } = useTroveView();

  const handleOpenTrove = useCallback(() => {
    dispatchEvent("OPEN_TROVE_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Flex
        sx={{
          alignItems: "center",
          gap: 2,
          pb: 3,
          borderBottom: 1,
          borderColor: "#263340",
        }}
      >
        <Image src={MintLogo} />
        <Heading sx={{}}>Mint USDEFI</Heading>
      </Flex>
      <Box sx={{ py: [2, 3], px: [0, 0] }}>
        <InfoMessage title="You haven't borrowed any USDEFI yet.">
          You can borrow USDEFI against BNB by opening a Mint. <br />
          Learn More:{" "}
          <Link
            sx={{ color: "text", textDecoration: "underline" }}
            href="https://dcntrl-network.gitbook.io/docs_en/user-guides/borrowing-minting-usdefi"
            target="_blank"
            rel="noopener noreferrer"
          >
            What is a Mint?
          </Link>
        </InfoMessage>

        <Flex variant="layout.actions">
          <Button onClick={handleOpenTrove}>Open Mint</Button>
        </Flex>
      </Box>
    </Card>
  );
};
