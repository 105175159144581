import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button, Image, Link } from "theme-ui";
import { InfoMessage } from "../InfoMessage";
import { useStabilityView } from "./context/StabilityViewContext";
import { RemainingLQTY } from "./RemainingLQTY";
import { Yield } from "./Yield";
import StabilityLogo from "../../assets/stability-pool-icon.svg";

export const NoDeposit: React.FC = (props) => {
  const { dispatchEvent } = useStabilityView();

  const handleOpenTrove = useCallback(() => {
    dispatchEvent("DEPOSIT_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          pb: 3,
          borderBottom: 1,
          borderColor: "#263340",
        }}
      >
        <Flex sx={{ alignItems: "center", gap: 2 }}>
          <Image src={StabilityLogo} />
          <Heading sx={{}}>Stability Pool</Heading>
        </Flex>
        <Yield />

        <RemainingLQTY />
      </Flex>
      <Box sx={{ py: [2, 3], px: [0, 0] }}>
        <InfoMessage title="You have no USDEFI in the Stability Pool.">
          You can earn DCNX rewards and buy BNB at a discount by depositing
          USDEFI. <br /> Learn more:{" "}
          <Link
            sx={{ color: "text", textDecoration: "underline" }}
            href="https://dcntrl-network.gitbook.io/docs_en/user-guides/start-earning-with-usdefi"
            target="_blank"
            rel="noopener noreferrer"
          >
            What is the Stability Pool?
          </Link>
        </InfoMessage>

        <Flex variant="layout.actions">
          <Button onClick={handleOpenTrove}>
            Deposit
          </Button>
        </Flex>
      </Box>
    </Card>
  );
};
