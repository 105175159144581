import React, { useCallback, useEffect, useState, useRef } from "react";
import { Flex, Button, Box, Card, Heading, Image, Text } from "theme-ui";
import {
  LiquityStoreState,
  Decimal,
  Trove,
  LUSD_LIQUIDATION_RESERVE,
  Percent,
  Difference,
} from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import MintLogo from "../../assets/mint-icon.svg";
import MintBlue from "../../assets/mint-blue.svg";
import WalletBlue from "../../assets/wallet-blue.svg";

import { useStableTroveChange } from "../../hooks/useStableTroveChange";
import { ActionDescription } from "../ActionDescription";
import { useMyTransactionState } from "../Transaction";
import { TroveAction } from "./TroveAction";
import { useTroveView } from "./context/TroveViewContext";
import { COIN } from "../../strings";
import { Icon } from "../Icon";
import { InfoIcon } from "../InfoIcon";
import { LoadingOverlay } from "../LoadingOverlay";
import { CollateralRatio } from "./CollateralRatio";
import { EditableRow, StaticRow } from "./Editor";
import {
  ExpensiveTroveChangeWarning,
  GasEstimationState,
} from "./ExpensiveTroveChangeWarning";
import {
  selectForTroveChangeValidation,
  validateTroveChange,
} from "./validation/validateTroveChange";
import Tabs from "../Tabs";
import { TroveManager } from "./TroveManager";
import { useBondView } from "../Bonds/context/BondViewContext";
import { useBondAddresses } from "../Bonds/context/BondAddressesContext";
import { CollateralSurplusAction } from "../CollateralSurplusAction";
import { ErrorDescription } from "../ErrorDescription";

const selector = (state: LiquityStoreState) => {
  const {
    trove,
    fees,
    price,
    accountBalance,
    lusdBalance,
    collateralSurplusBalance,
  } = state;
  return {
    trove,
    fees,
    price,
    accountBalance,
    lusdBalance,
    validationContext: selectForTroveChangeValidation(state),
    hasSurplusCollateral: !collateralSurplusBalance.isZero,
  };
};

const TRANSACTION_ID = "trove-adjustment";
const GAS_ROOM_ETH = Decimal.from(0.1);

const feeFrom = (
  original: Trove,
  edited: Trove,
  borrowingRate: Decimal
): Decimal => {
  const change = original.whatChanged(edited, borrowingRate);

  if (change && change.type !== "invalidCreation" && change.params.borrowLUSD) {
    return change.params.borrowLUSD.mul(borrowingRate);
  } else {
    return Decimal.ZERO;
  }
};

const applyUnsavedCollateralChanges = (
  unsavedChanges: Difference,
  trove: Trove
) => {
  if (unsavedChanges.absoluteValue) {
    if (unsavedChanges.positive) {
      return trove.collateral.add(unsavedChanges.absoluteValue);
    }
    if (unsavedChanges.negative) {
      if (unsavedChanges.absoluteValue.lt(trove.collateral)) {
        return trove.collateral.sub(unsavedChanges.absoluteValue);
      }
    }
    return trove.collateral;
  }
  return trove.collateral;
};

const applyUnsavedNetDebtChanges = (
  unsavedChanges: Difference,
  trove: Trove
) => {
  if (unsavedChanges.absoluteValue) {
    if (unsavedChanges.positive) {
      return trove.netDebt.add(unsavedChanges.absoluteValue);
    }
    if (unsavedChanges.negative) {
      if (unsavedChanges.absoluteValue.lt(trove.netDebt)) {
        return trove.netDebt.sub(unsavedChanges.absoluteValue);
      }
    }
    return trove.netDebt;
  }
  return trove.netDebt;
};
type AdjustingProps = {
  currentTab: string;
  tabs: string[];
  setCurrentTab: (value: string) => void;
};

export const Adjusting: React.FC<AdjustingProps> = ({
  tabs,
  currentTab,
  setCurrentTab,
}) => {
  const { dispatchEvent } = useTroveView();
  const {
    trove,
    fees,
    price,
    accountBalance,
    validationContext,
    lusdBalance: realLusdBalance,
    hasSurplusCollateral,
  } = useLiquitySelector(selector);
  const { bLusdBalance, lusdBalance: customLusdBalance } = useBondView();
  const { LUSD_OVERRIDE_ADDRESS } = useBondAddresses();

  const lusdBalance =
    LUSD_OVERRIDE_ADDRESS === null ? realLusdBalance : customLusdBalance;
  const editingState = useState<string>();
  const previousTrove = useRef<Trove>(trove);
  const [collateral, setCollateral] = useState<Decimal>(trove.collateral);
  const [netDebt, setNetDebt] = useState<Decimal>(trove.netDebt);
  const [collatteralEntered, setCollatteralEntered] = useState(Decimal.from(0));
  const [netDebtEntered, setNetDebtEntered] = useState(Decimal.from(0));

  const transactionState = useMyTransactionState(TRANSACTION_ID);

  const borrowingRate = fees.borrowingRate();

  useEffect(() => {
    if (transactionState.type === "confirmedOneShot") {
      reset();
      dispatchEvent("TROVE_ADJUSTED");
    }
  }, [transactionState.type, dispatchEvent]);

  useEffect(() => {
    reset();
  }, [currentTab]);

  // useEffect(() => {
  //   if (!previousTrove.current.collateral.eq(trove.collateral)) {
  //     const unsavedChanges = Difference.between(
  //       collateral,
  //       previousTrove.current.collateral
  //     );
  //     const nextCollateral = applyUnsavedCollateralChanges(
  //       unsavedChanges,
  //       trove
  //     );
  //     setCollateral(nextCollateral);
  //   }
  //   if (!previousTrove.current.netDebt.eq(trove.netDebt)) {
  //     const unsavedChanges = Difference.between(
  //       netDebt,
  //       previousTrove.current.netDebt
  //     );
  //     const nextNetDebt = applyUnsavedNetDebtChanges(unsavedChanges, trove);
  //     setNetDebt(nextNetDebt);
  //   }
  //   previousTrove.current = trove;
  // }, [trove, collateral, netDebt]);
  const [closeStage, setCloseStage] = useState(false);
  useEffect(() => {
    if (
      (currentTab === tabs[1] &&
        !collatteralEntered.isZero &&
        collateral.isZero) ||
      (!netDebtEntered.isZero && netDebt.isZero) ||
      (netDebtEntered.gt(
        trove.netDebt
          // .add(LUSD_LIQUIDATION_RESERVE)
          // .add(fee)
          .sub(Decimal.from(2))
      ) &&
        netDebtEntered.lt(
          trove.netDebt
            // .add(LUSD_LIQUIDATION_RESERVE)
            // .add(fee)
            .add(Decimal.from(2))
        ))
    ) {
      setCloseStage(true);
    } else {
      setCloseStage(false);
    }
  }, [netDebtEntered, collatteralEntered, currentTab]);

  const handleCancelPressed = useCallback(() => {
    dispatchEvent("CANCEL_ADJUST_TROVE_PRESSED");
  }, [dispatchEvent]);

  const reset = useCallback(() => {
    setCollateral(trove.collateral);
    setNetDebt(trove.netDebt);
    setCollatteralEntered(Decimal.ZERO);
    setNetDebtEntered(Decimal.ZERO);
  }, [trove.collateral, trove.netDebt]);

  const isDirty =
    !collateral.eq(trove.collateral) ||
    !netDebt.eq(trove.netDebt) ||
    !netDebtEntered.isZero ||
    !collatteralEntered.isZero;
  const isDebtIncrease = netDebt.gt(trove.netDebt);
  const debtIncreaseAmount = isDebtIncrease
    ? netDebt.sub(trove.netDebt)
    : Decimal.ZERO;

  const fee = isDebtIncrease
    ? feeFrom(
        trove,
        new Trove(trove.collateral, trove.debt.add(debtIncreaseAmount)),
        borrowingRate
      )
    : Decimal.ZERO;
  const totalDebt = netDebt.add(LUSD_LIQUIDATION_RESERVE).add(fee);
  const maxBorrowingRate = borrowingRate.add(0.005);
  const updatedTrove = isDirty ? new Trove(collateral, totalDebt) : trove;
  const feePct = new Percent(borrowingRate);
  const availableEth = accountBalance.gt(GAS_ROOM_ETH)
    ? accountBalance.sub(GAS_ROOM_ETH)
    : Decimal.ZERO;
  const maxCollateral =
    currentTab === tabs[0] ? availableEth : trove.collateral;
  const collateralMaxedOut =
    currentTab === tabs[0]
      ? collateral.eq(maxCollateral)
      : collateral.eq(Decimal.ZERO);
  const maxNetDebt =
    currentTab === tabs[0] ? lusdBalance || Decimal.ZERO : trove.netDebt;
  const netDebtMaxedOut =
    currentTab === tabs[0] ? netDebt.eq(maxNetDebt) : netDebt.eq(Decimal.ZERO);
  const collateralRatio =
    !collateral.isZero && !netDebt.isZero
      ? updatedTrove.collateralRatio(price)
      : undefined;
  const collateralRatioChange = Difference.between(
    collateralRatio,
    trove.collateralRatio(price)
  );

  const [troveChange, description] = validateTroveChange(
    trove,
    updatedTrove,
    borrowingRate,
    validationContext,
    closeStage
  );

  const stableTroveChange = useStableTroveChange(troveChange);
  const [
    gasEstimationState,
    setGasEstimationState,
  ] = useState<GasEstimationState>({ type: "idle" });

  const isTransactionPending =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  if (trove.status !== "open") {
    return null;
  }

  const handleMaxUSDEFI = () => {
    let newNetDebt = !trove.netDebt.isZero
      ? collateral.mul(price).div(Decimal.from(1.538))
      : Decimal.from(0);
    let newEnteredNetDebt = newNetDebt.gt(trove.netDebt)
      ? newNetDebt.sub(trove.netDebt)
      : Decimal.from(0);
    setNetDebtEntered(newEnteredNetDebt);
    setNetDebt(newNetDebt);
  };

  const handleMaxRemove = () => {
    let newMaxCollateral = trove.collateral;
    setCollatteralEntered(newMaxCollateral);
    setCollateral(Decimal.ZERO);
    let newMaxNetDebt = trove.netDebt;
    setNetDebtEntered(newMaxNetDebt);
    setNetDebt(Decimal.ZERO);
  };

  return (
    <Card>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          pb: 3,
          borderBottom: 1,
          borderColor: "#263340",
        }}
      >
        <Flex sx={{ alignItems: "center", gap: 2 }}>
          <Image src={MintLogo} />
          <Heading sx={{}}>
            Mint USDEFI
            {/* {isDirty && !isTransactionPending && (
            <Button
              variant="titleIcon"
              sx={{ ":enabled:hover": { color: "danger" } }}
              onClick={reset}
            >
              <Icon name="history" size="lg" />
            </Button>
          )} */}
          </Heading>
        </Flex>
        {isDirty && !isTransactionPending && (
          <Button
            variant="titleIcon"
            sx={{ ":enabled:hover": { color: "danger" } }}
            onClick={reset}
          >
            <Icon name="history" size="lg" />
          </Button>
        )}
      </Flex>
      <Box sx={{ py: [2, 3], px: [0, 0] }}>
        <Flex
          sx={{
            justifyContent: "space-between",
            width: "100%",
            px: "38px",
            mb: "6px",
          }}
        >
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          <Flex sx={{ color: "#0C94C0", alignItems: "center", gap: 1 }}>
            <Image src={MintBlue} width={"15px"} height={"auto"} />

            <Text sx={{ fontSize: "14px" }}>
              {trove.collateral.prettify(4)}
            </Text>
          </Flex>
        </Flex>
        <EditableRow
          label={
            currentTab === tabs[0] ? "Add Collateral" : "Remove Collateral"
          }
          inputId="trove-collateral"
          amount={collatteralEntered.prettify(4)}
          maxAmount={maxCollateral.toString(2)}
          maxedOut={collateralMaxedOut}
          maxFunction={currentTab === tabs[0] ? undefined : handleMaxRemove}
          editingState={editingState}
          unit="BNB"
          editedAmount={collatteralEntered.toString(4)}
          setEditedAmount={(amount: string) => {
            const decimalPlaces = amount.split(".")[1]?.length || 0;
            if (decimalPlaces <= 4) {
              setCollatteralEntered(Decimal.from(amount));
              if (currentTab === tabs[0]) {
                setCollateral(trove.collateral.add(Decimal.from(amount)));
              } else {
                setCollateral(
                  trove.collateral.gte(Decimal.from(amount))
                    ? trove.collateral.sub(Decimal.from(amount))
                    : trove.collateral
                );
              }
              setNetDebtEntered(
                Decimal.from(amount)
                  .mul(price)
                  .div(
                    !collateral.isZero && !netDebt.isZero
                      ? trove.collateralRatio(price)
                      : Decimal.from(1.1546)
                  )
              );
              setNetDebt(
                trove.netDebt.gte(
                  Decimal.from(amount)
                    .mul(price)
                    .div(
                      !collateral.isZero && !netDebt.isZero
                        ? trove.collateralRatio(price)
                        : Decimal.from(1.1546)
                    )
                )
                  ? trove.netDebt.sub(
                      Decimal.from(amount)
                        .mul(price)
                        .div(
                          !collateral.isZero && !netDebt.isZero
                            ? trove.collateralRatio(price)
                            : Decimal.from(1.1546)
                        )
                    )
                  : trove.netDebt
              );
            } else {
              console.log("Cannot Enter More than 4 Decimals");
            }
          }}
        />
        <Flex
          sx={{
            justifyContent: "space-between",
            width: "100%",
            px: "38px",
            mb: "6px",
          }}
        >
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            secondary
          />
          <Flex sx={{ color: "#0C94C0", alignItems: "center", gap: 1 }}>
            <Image src={WalletBlue} width={"15px"} height={"auto"} />
            {([
              // ["BNB", accountBalance, "#0C94C0"],
              [COIN, Decimal.from(lusdBalance || 0), "#0C94C0"],
              // ["bLUSD", Decimal.from(bLusdBalance || 0)]
            ] as const).map(([currency, balance, color], i) => (
              <Text key={i} sx={{ fontSize: "14px", color: color }}>
                {balance.prettify(4)}
              </Text>
            ))}
          </Flex>
        </Flex>
        <EditableRow
          label={currentTab === tabs[0] ? "Borrow" : "Repay"}
          inputId="trove-net-debt-amount"
          amount={netDebtEntered.prettify()}
          unit={COIN}
          editingState={editingState}
          editedAmount={netDebtEntered.toString(2)}
          maxAmount={maxNetDebt.toString(2)}
          maxedOut={netDebtMaxedOut}
          maxFunction={
            currentTab === tabs[0] ? handleMaxUSDEFI : handleMaxRemove
          }
          setEditedAmount={(amount: string) => {
            const decimalPlaces = amount.split(".")[1]?.length || 0;
            if (decimalPlaces <= 2) {
              setNetDebtEntered(Decimal.from(amount));
              if (currentTab === tabs[0]) {
                setNetDebt(trove.netDebt.add(Decimal.from(amount)));
              } else {
                setNetDebt(
                  trove.netDebt.gte(Decimal.from(amount))
                    ? trove.netDebt.sub(Decimal.from(amount))
                    : trove.netDebt
                );
                console.log(
                  Number(
                    trove.netDebt.gte(Decimal.from(amount))
                      ? trove.netDebt.sub(Decimal.from(amount))
                      : trove.netDebt
                  )
                );
              }
            } else {
              console.log("Cannot Enter More than 2 Decimals");
            }
          }}
        />

        <StaticRow
          label="Liquidation Reserve"
          inputId="trove-liquidation-reserve"
          amount={`${LUSD_LIQUIDATION_RESERVE}`}
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "200px" }}>
                  An amount set aside to cover the liquidator’s gas costs if
                  your MINT needs to be liquidated. The amount increases your
                  debt and is refunded if you close your MINT by fully paying
                  off its net debt.
                </Card>
              }
            />
          }
        />

        <StaticRow
          label="Borrowing Fee"
          inputId="trove-borrowing-fee"
          amount={fee.prettify(2)}
          pendingAmount={feePct.toString(2)}
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "240px" }}>
                  This amount is deducted from the borrowed amount as a one-time
                  fee. There are no recurring fees for borrowing, which is thus
                  interest-free.
                </Card>
              }
            />
          }
        />

        <StaticRow
          label="Total debt"
          inputId="trove-total-debt"
          amount={
            (!netDebtEntered.isZero && netDebt.isZero) || closeStage
              ? Decimal.ZERO.prettify(2)
              : totalDebt.prettify(2)
          }
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "240px" }}>
                  The total amount of USDEFI your MINT will hold.{" "}
                  {isDirty && (
                    <>
                      You will need to repay{" "}
                      {totalDebt.sub(LUSD_LIQUIDATION_RESERVE).prettify(2)}{" "}
                      USDEFI to reclaim your collateral (
                      {LUSD_LIQUIDATION_RESERVE.toString()} USDEFI Liquidation
                      Reserve excluded).
                    </>
                  )}
                </Card>
              }
            />
          }
        />

        <CollateralRatio
          value={collateralRatio}
          change={collateralRatioChange}
        />

        {description ?? (
          <ActionDescription>
            Adjust your Trove by modifying its collateral, debt, or both.
          </ActionDescription>
        )}

        {closeStage ? (
          <>
            {" "}
            {lusdBalance && trove.netDebt.gt(lusdBalance) && (
              <ErrorDescription>
                Repay Amount Greater Than Wallet Balance
              </ErrorDescription>
            )}
            <TroveManager
              // {...props}
              reset={reset}
              collateral={Decimal.ZERO}
              debt={Decimal.ZERO}
            />
          </>
        ) : (
          <>
            <ExpensiveTroveChangeWarning
              troveChange={stableTroveChange}
              maxBorrowingRate={maxBorrowingRate}
              borrowingFeeDecayToleranceMinutes={60}
              gasEstimationState={gasEstimationState}
              setGasEstimationState={setGasEstimationState}
            />

            <Flex variant="layout.actions">
              {/* <Button variant="cancel" onClick={handleCancelPressed}>
                Cancel
              </Button> */}

              {stableTroveChange ? (
                <TroveAction
                  transactionId={TRANSACTION_ID}
                  change={stableTroveChange}
                  maxBorrowingRate={maxBorrowingRate}
                  borrowingFeeDecayToleranceMinutes={60}
                >
                  Confirm
                </TroveAction>
              ) : (
                <Button disabled>Confirm</Button>
              )}
              {hasSurplusCollateral && <CollateralSurplusAction />}
            </Flex>
          </>
        )}
      </Box>
      {isTransactionPending && <LoadingOverlay />}
    </Card>
  );
};
