import { Flex, Box, Badge, Text, Link } from "theme-ui";
import { Link as Link2 } from "./Link";
import { LiquityLogo } from "./LiquityLogo";
const logoHeight = "35px";
const TemporaryNewBadge = () => {
  const isBeforeNovember2022 = new Date() < new Date("2022-11-01");
  if (!isBeforeNovember2022) return null;
  return (
    <Badge ml={1} sx={{ fontSize: "12px" }}>
      New
    </Badge>
  );
};


export const Nav: React.FC<{ scrollThreshold: number }> = ({ scrollThreshold }) => {
  const linksx = {
    px: ['10px','20px'],
    py: ["20px", "20px", "50px"],
        //  pb: ["20px", "30px", "40px"],
    '&.active , :hover': {
    color: 'primary',
    borderBottom: '1px solid #0087B2',
    // background: ['linear-gradient(180deg, #162029 33.97%, rgba(19, 27, 35, 0) 100%), linear-gradient(100.96deg, #012c38 0%, #012c38 100%)','linear-gradient(180deg, #131B23 33.97%, rgba(19, 27, 35, 0) 100%), linear-gradient(100.96deg, #012c38 0%, #012c38 100%)'],
      background: ['linear-gradient(180deg, #162029 33.97%, rgba(19, 27, 35, 0) 100%), linear-gradient(100.96deg, #012c38 0%, #012c38 100%)',scrollThreshold>50? 'linear-gradient(180deg, transparent 33.97%, rgba(19, 27, 35, 0) 100%), linear-gradient(100.96deg, rgba(1, 4, 56, .1) 0%, rgba(1, 4, 56, .1) 100%)':'linear-gradient(180deg, #131B23 33.97%, rgba(19, 27, 35, 0) 100%), linear-gradient(100.96deg, #012c38 0%, #012c38 100%)'],
     
    }
  }
  const linksx2 = {
    px: ['10px','20px'],
    py: ["20px", "20px", "50px"],
        //  pb: ["20px", "30px", "40px"],
    '&.active , :hover': {
    // color: 'primary',
    borderBottom: '1px solid #0087B2',
    background: ['linear-gradient(180deg, #162029 33.97%, rgba(19, 27, 35, 0) 100%), linear-gradient(100.96deg, #012c38 0%, #012c38 100%)',scrollThreshold>50? 'linear-gradient(180deg, transparent 33.97%, rgba(19, 27, 35, 0) 100%), linear-gradient(100.96deg, rgba(1, 4, 56, .1) 0%, rgba(1, 4, 56, .1) 100%)':'linear-gradient(180deg, #131B23 33.97%, rgba(19, 27, 35, 0) 100%), linear-gradient(100.96deg, #012c38 0%, #012c38 100%)'],
   
  }}
  return (
    <Box as="nav" sx={{
      // display: ["none", "flex"],
      // mx:[1,4],
      alignItems: "center",
      width:'100%'
      // flex: 1,
    }}> 
      <Flex sx={{ gap: [1,2],alignItems:'center',justifyContent:['space-between','flex-start'],width:['100%','auto'] }}>
      <LiquityLogo height={logoHeight} sx={{display:['flex','none']}} />
        <Link2 sx={linksx} to="/">Dashboard</Link2>
        <Link2 sx={linksx} to="/risky-mints">
          Risky Mints
        </Link2>
        {/* <Link2 sx={linksx} to="/redemption">Redemption</Link2>
        <Link2 sx={linksx} to="/farm">Farm</Link2> */}
        <Link sx={linksx2} href="https://lend.finance"
          // target="_blank" rel="noopener noreferrer"
        >
          {/* <Flex sx={{ alignItems: "center" }}> */}
            <Text>Lending</Text>
            {/* <TemporaryNewBadge /> */}
          {/* </Flex> */}
        </Link>
        
      </Flex>
      {/* <Flex sx={{ justifyContent: "flex-end", mr: 3, flex: 1 }}>
        <Link sx={{ fontSize: 1 }} to="/risky-troves">
          Risky Troves
        </Link>
      </Flex> */}
    </Box>
  );
};
