import { useStakingView } from "./context/StakingViewContext";
import { ReadOnlyStake } from "./ReadOnlyStake";
import { StakingManager } from "./StakingManager";
import { NoStake } from "./NoStake";
import { useState } from "react";

export const Staking: React.FC = () => {
  const { view } = useStakingView();

  const tabs = ["Stake DCNX", "Unstake DCNX"];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  switch (view) {
    case "ACTIVE":
      // return <ReadOnlyStake tabs={ tabs} currentTab={currentTab} />;
      return (
        <StakingManager
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );

    case "ADJUSTING":
      return (
        <StakingManager
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );

    case "NONE":
      return <NoStake />;
  }
};
