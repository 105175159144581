"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurveRegistrySwaps__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: "buyer",
                type: "address",
            },
            {
                indexed: true,
                name: "receiver",
                type: "address",
            },
            {
                indexed: true,
                name: "pool",
                type: "address",
            },
            {
                indexed: false,
                name: "token_sold",
                type: "address",
            },
            {
                indexed: false,
                name: "token_bought",
                type: "address",
            },
            {
                indexed: false,
                name: "amount_sold",
                type: "uint256",
            },
            {
                indexed: false,
                name: "amount_bought",
                type: "uint256",
            },
        ],
        name: "TokenExchange",
        type: "event",
    },
    {
        inputs: [
            {
                name: "_address_provider",
                type: "address",
            },
            {
                name: "_calculator",
                type: "address",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        stateMutability: "payable",
        type: "fallback",
    },
    {
        inputs: [
            {
                name: "_from",
                type: "address",
            },
            {
                name: "_to",
                type: "address",
            },
            {
                name: "_amount",
                type: "uint256",
            },
            {
                name: "_expected",
                type: "uint256",
            },
        ],
        name: "exchange_with_best_rate",
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_from",
                type: "address",
            },
            {
                name: "_to",
                type: "address",
            },
            {
                name: "_amount",
                type: "uint256",
            },
            {
                name: "_expected",
                type: "uint256",
            },
            {
                name: "_receiver",
                type: "address",
            },
        ],
        name: "exchange_with_best_rate",
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_pool",
                type: "address",
            },
            {
                name: "_from",
                type: "address",
            },
            {
                name: "_to",
                type: "address",
            },
            {
                name: "_amount",
                type: "uint256",
            },
            {
                name: "_expected",
                type: "uint256",
            },
        ],
        name: "exchange",
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_pool",
                type: "address",
            },
            {
                name: "_from",
                type: "address",
            },
            {
                name: "_to",
                type: "address",
            },
            {
                name: "_amount",
                type: "uint256",
            },
            {
                name: "_expected",
                type: "uint256",
            },
            {
                name: "_receiver",
                type: "address",
            },
        ],
        name: "exchange",
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_route",
                type: "address[9]",
            },
            {
                name: "_swap_params",
                type: "uint256[3][4]",
            },
            {
                name: "_amount",
                type: "uint256",
            },
            {
                name: "_expected",
                type: "uint256",
            },
        ],
        name: "exchange_multiple",
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_route",
                type: "address[9]",
            },
            {
                name: "_swap_params",
                type: "uint256[3][4]",
            },
            {
                name: "_amount",
                type: "uint256",
            },
            {
                name: "_expected",
                type: "uint256",
            },
            {
                name: "_pools",
                type: "address[4]",
            },
        ],
        name: "exchange_multiple",
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_route",
                type: "address[9]",
            },
            {
                name: "_swap_params",
                type: "uint256[3][4]",
            },
            {
                name: "_amount",
                type: "uint256",
            },
            {
                name: "_expected",
                type: "uint256",
            },
            {
                name: "_pools",
                type: "address[4]",
            },
            {
                name: "_receiver",
                type: "address",
            },
        ],
        name: "exchange_multiple",
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_from",
                type: "address",
            },
            {
                name: "_to",
                type: "address",
            },
            {
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "get_best_rate",
        outputs: [
            {
                name: "",
                type: "address",
            },
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_from",
                type: "address",
            },
            {
                name: "_to",
                type: "address",
            },
            {
                name: "_amount",
                type: "uint256",
            },
            {
                name: "_exclude_pools",
                type: "address[8]",
            },
        ],
        name: "get_best_rate",
        outputs: [
            {
                name: "",
                type: "address",
            },
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_pool",
                type: "address",
            },
            {
                name: "_from",
                type: "address",
            },
            {
                name: "_to",
                type: "address",
            },
            {
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "get_exchange_amount",
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_pool",
                type: "address",
            },
            {
                name: "_from",
                type: "address",
            },
            {
                name: "_to",
                type: "address",
            },
            {
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "get_input_amount",
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_pool",
                type: "address",
            },
            {
                name: "_from",
                type: "address",
            },
            {
                name: "_to",
                type: "address",
            },
            {
                name: "_amounts",
                type: "uint256[100]",
            },
        ],
        name: "get_exchange_amounts",
        outputs: [
            {
                name: "",
                type: "uint256[100]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_route",
                type: "address[9]",
            },
            {
                name: "_swap_params",
                type: "uint256[3][4]",
            },
            {
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "get_exchange_multiple_amount",
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_route",
                type: "address[9]",
            },
            {
                name: "_swap_params",
                type: "uint256[3][4]",
            },
            {
                name: "_amount",
                type: "uint256",
            },
            {
                name: "_pools",
                type: "address[4]",
            },
        ],
        name: "get_exchange_multiple_amount",
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_pool",
                type: "address",
            },
        ],
        name: "get_calculator",
        outputs: [
            {
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "update_registry_address",
        outputs: [
            {
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_pool",
                type: "address",
            },
            {
                name: "_calculator",
                type: "address",
            },
        ],
        name: "set_calculator",
        outputs: [
            {
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_calculator",
                type: "address",
            },
        ],
        name: "set_default_calculator",
        outputs: [
            {
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_token",
                type: "address",
            },
        ],
        name: "claim_balance",
        outputs: [
            {
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                name: "_is_killed",
                type: "bool",
            },
        ],
        name: "set_killed",
        outputs: [
            {
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "registry",
        outputs: [
            {
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "factory_registry",
        outputs: [
            {
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "crypto_registry",
        outputs: [
            {
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "default_calculator",
        outputs: [
            {
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "is_killed",
        outputs: [
            {
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
class CurveRegistrySwaps__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.CurveRegistrySwaps__factory = CurveRegistrySwaps__factory;
CurveRegistrySwaps__factory.abi = _abi;
