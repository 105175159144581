import React, { useEffect, useState } from "react";
import { Heading, Box, Card, Button, Image, Flex, Text } from "theme-ui";

import {
  Decimal,
  Decimalish,
  Difference,
  LiquityStoreState,
  LQTYStake,
} from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import StakingLogo from "../../assets/staking-icon.svg";
import StakingBlue from "../../assets/staking-blue.svg";

import { COIN, GT } from "../../strings";

import { Icon } from "../Icon";
import { EditableRow, StaticRow } from "../Trove/Editor";
import { LoadingOverlay } from "../LoadingOverlay";

import { useStakingView } from "./context/StakingViewContext";
import Tabs from "../Tabs";
import { roundDown } from "../../utils/rounder";
import { useMyTransactionState } from "../Transaction";

const select = ({ lqtyBalance, totalStakedLQTY }: LiquityStoreState) => ({
  lqtyBalance,
  totalStakedLQTY,
});

type StakingEditorProps = {
  title: string;
  originalStake: LQTYStake;
  editedLQTY: Decimal;
  currentTab: string;
  tabs: string[];
  setCurrentTab: (value: string) => void;
  dispatch: (
    action: { type: "setStake"; newValue: Decimalish } | { type: "revert" }
  ) => void;
};

export const StakingEditor: React.FC<StakingEditorProps> = ({
  children,
  title,
  originalStake,
  editedLQTY,
  dispatch,
  tabs,
  currentTab,
  setCurrentTab,
}) => {
  const { lqtyBalance, totalStakedLQTY } = useLiquitySelector(select);
  const { changePending } = useStakingView();
  const editingState = useState<string>();

  const edited = !editedLQTY.eq(originalStake.stakedLQTY);
  const [enteredLQTY, setEnteredLQTY] = useState(Decimal.from(0));
  const maxAmount =
    currentTab === tabs[0]
      ? roundDown(lqtyBalance)
      : roundDown(originalStake.stakedLQTY);
  const maxedOut =
    currentTab === tabs[0]
      ? roundDown(editedLQTY).eq(maxAmount)
      : editedLQTY.lt(Decimal.from("0.01"));
  const makingNewStake = originalStake.isEmpty;
  const totalStakedLQTYAfterChange = totalStakedLQTY
    .sub(originalStake.stakedLQTY)
    .add(editedLQTY);

  const originalPoolShare = originalStake.stakedLQTY.mulDiv(
    100,
    totalStakedLQTY
  );
  const newPoolShare = editedLQTY.mulDiv(100, totalStakedLQTYAfterChange);
  const poolShareChange =
    originalStake.stakedLQTY.nonZero &&
    Difference.between(newPoolShare, originalPoolShare).nonZero;

  const transactionState = useMyTransactionState("stake");

  useEffect(() => {
    if (transactionState.type === "confirmedOneShot") {
      dispatch({ type: "revert" });
      setEnteredLQTY(Decimal.from(0));
    }
  }, [transactionState.type, dispatch]);

  useEffect(() => {
    dispatch({ type: "revert" });
    setEnteredLQTY(Decimal.from(0));
  }, [currentTab]);

  return (
    <Card>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          pb: 3,
          borderBottom: 1,
          borderColor: "#263340",
        }}
      >
        <Flex sx={{ alignItems: "center", gap: 2 }}>
          <Image src={StakingLogo} />
          <Heading sx={{}}>{title}</Heading>
        </Flex>
        {edited && !changePending && (
          <Button
            variant="titleIcon"
            sx={{ ":enabled:hover": { color: "danger" } }}
            onClick={() => dispatch({ type: "revert" })}
          >
            <Icon name="history" size="lg" />
          </Button>
        )}
      </Flex>

      <Box sx={{ py: [2, 3], px: [0, 0] }}>
        <Flex
          sx={{
            justifyContent: "space-between",
            width: "100%",
            px: "38px",
            mb: "6px",
          }}
        >
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          <Flex sx={{ color: "#0C94C0", alignItems: "center", gap: 1 }}>
            <Image src={StakingBlue} width={"15px"} height={"auto"} />
            <Text sx={{ fontSize: "14px" }}>
              {originalStake.stakedLQTY.prettify()}
            </Text>
          </Flex>
        </Flex>

        <EditableRow
          label={currentTab === tabs[0] ? "Stake" : "Unstake"}
          inputId="stake-lqty"
          amount={enteredLQTY.prettify(2)}
          maxAmount={maxAmount.toString(2)}
          maxedOut={maxedOut}
          unit={GT}
          {...{ editingState }}
          editedAmount={enteredLQTY.toString(2)}
          setEditedAmount={(newValue) => {
            const decimalPlaces = newValue.split(".")[1]?.length || 0;
            if (decimalPlaces <= 2) {
              setEnteredLQTY(Decimal.from(newValue));
              dispatch({
                type: "setStake",
                newValue:
                  currentTab === tabs[0]
                    ? Decimal.from(newValue).add(originalStake.stakedLQTY)
                    : Decimal.from(newValue).gte(originalStake.stakedLQTY)
                    ? Decimal.from(0)
                    : originalStake.stakedLQTY.sub(Decimal.from(newValue)),
              });
            } else {
              console.log("Cannot Enter More than 2 Decimals");
            }
          }}
        />

        {newPoolShare.infinite ? (
          <StaticRow label="Pool share" inputId="stake-share" amount="N/A" />
        ) : (
          <StaticRow
            label="Pool share"
            inputId="stake-share"
            amount={newPoolShare.prettify(2).concat("%")}
            pendingAmount={poolShareChange?.prettify(2).concat("%")}
            pendingColor={poolShareChange?.positive ? "success" : "danger"}
            unit="%"
          />
        )}

        {!originalStake.isEmpty && (
          <>
            <StaticRow
              label="Redemption gain"
              inputId="stake-gain-eth"
              amount={originalStake.collateralGain.prettify(4).concat(" BNB")}
              color={originalStake.collateralGain.nonZero && "success"}
              unit="BNB"
            />

            <StaticRow
              label="Issuance gain"
              inputId="stake-gain-lusd"
              amount={originalStake.lusdGain.prettify().concat(` ${COIN}`)}
              color={originalStake.lusdGain.nonZero && "success"}
              unit={COIN}
            />
          </>
        )}

        {children}
      </Box>

      {changePending && <LoadingOverlay />}
    </Card>
  );
};
