import React from "react";
import { Container, Spinner } from "theme-ui";

export const LoadingOverlay: React.FC = () => (
  <Container
    variant="disabledOverlay"
    sx={{
      p: "14px",
      display: "flex",
      justifyContent: "flex-end",
      borderRadius: "16px",
    }}
  >
    <Spinner size="28px" sx={{ color: "text", mx: "auto" }} />
  </Container>
);
