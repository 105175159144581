"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurveRegistrySwaps__factory = exports.CurveLiquidityGaugeV5__factory = exports.CurveCryptoSwap2ETH__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var CurveCryptoSwap2ETH__factory_1 = require("./CurveCryptoSwap2ETH__factory");
Object.defineProperty(exports, "CurveCryptoSwap2ETH__factory", { enumerable: true, get: function () { return CurveCryptoSwap2ETH__factory_1.CurveCryptoSwap2ETH__factory; } });
var CurveLiquidityGaugeV5__factory_1 = require("./CurveLiquidityGaugeV5__factory");
Object.defineProperty(exports, "CurveLiquidityGaugeV5__factory", { enumerable: true, get: function () { return CurveLiquidityGaugeV5__factory_1.CurveLiquidityGaugeV5__factory; } });
var CurveRegistrySwaps__factory_1 = require("./CurveRegistrySwaps__factory");
Object.defineProperty(exports, "CurveRegistrySwaps__factory", { enumerable: true, get: function () { return CurveRegistrySwaps__factory_1.CurveRegistrySwaps__factory; } });
