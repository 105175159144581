import React, { useState } from "react";
import { TroveManager } from "./TroveManager";
import { ReadOnlyTrove } from "./ReadOnlyTrove";
import { NoTrove } from "./NoTrove";
import { Opening } from "./Opening";
import { Adjusting } from "./Adjusting";
import { RedeemedTrove } from "./RedeemedTrove";
import { useTroveView } from "./context/TroveViewContext";
import { LiquidatedTrove } from "./LiquidatedTrove";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

const selector = (state: LiquityStoreState) => {
  const { trove } = state;
  return {
    trove,
  };
};

export const Trove: React.FC = (props) => {
  const { trove } = useLiquitySelector(selector);
  const { view } = useTroveView();
  const tabs = ["Add Collateral", "Remove Collateral"];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  trove.debt.lt(Decimal.from("50"));

  switch (view) {
    // loading state not needed, as main app has a loading spinner that blocks render until the liquity backend data is available
    case "ACTIVE": {
      return (
        <>
          {trove.debt.lt(Decimal.from("50")) ? (
            <Opening
              {...props}
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          ) : (
            <Adjusting
              {...props}
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          )}
        </>
      );
    }
    case "ADJUSTING": {
      return (
        <>
          {trove.debt.lt(Decimal.from("50")) ? (
            <Opening
              {...props}
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          ) : (
            <Adjusting
              {...props}
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          )}
        </>
      );
    }
    case "CLOSING": {
      return (
        <>
          {trove.debt.lt(Decimal.from("50")) ? (
            <Opening
              {...props}
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          ) : (
            <Adjusting
              {...props}
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          )}
        </>
      );
    }
    case "OPENING": {
      return (
        <Opening
          {...props}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
    case "LIQUIDATED": {
      return <LiquidatedTrove {...props} />;
    }
    case "REDEEMED": {
      return <RedeemedTrove {...props} />;
    }
    case "NONE": {
      return <NoTrove {...props} />;
    }
  }
};
