import React from "react";
import { Card, Heading, Link, Box, Text, Flex, Image } from "theme-ui";
import { AddressZero } from "@ethersproject/constants";
import {
  Decimal,
  Percent,
  LiquityStoreState,
  Decimalish,
} from "@liquity/lib-base";
import {
  LiquityStoreUpdate,
  useLiquityReducer,
  useLiquitySelector,
} from "@liquity/lib-react";
import BalanceLogo from "../assets/balance-icon.svg";
import { useLiquity } from "../hooks/LiquityContext";
import { Statistic } from "./Statistic";
import * as l from "../lexicon";
import { Icon } from "./Icon";
import { useBondView } from "./Bonds/context/BondViewContext";
import { useBondAddresses } from "./Bonds/context/BondAddressesContext";
import { COIN, GT } from "../strings";
import { InfoIcon } from "./InfoIcon";

type SystemStatsProps = {
  variant?: string;
  showBalances?: boolean;
};
const select = ({
  accountBalance,
  lusdBalance,
  lqtyBalance,
}: LiquityStoreState) => ({
  accountBalance,
  lusdBalance,
  lqtyBalance,
});

const init = ({ stabilityDeposit }: LiquityStoreState) => ({
  originalDeposit: stabilityDeposit,
  editedLUSD: stabilityDeposit.currentLUSD,
  changePending: false,
});

type StabilityDepositManagerState = ReturnType<typeof init>;
type StabilityDepositManagerAction =
  | LiquityStoreUpdate
  | { type: "startChange" | "finishChange" | "revert" }
  | { type: "setDeposit"; newValue: Decimalish };

const reduceWith = (action: StabilityDepositManagerAction) => (
  state: StabilityDepositManagerState
): StabilityDepositManagerState => reduce(state, action);

const finishChange = reduceWith({ type: "finishChange" });
const revert = reduceWith({ type: "revert" });

const reduce = (
  state: StabilityDepositManagerState,
  action: StabilityDepositManagerAction
): StabilityDepositManagerState => {
  // console.log(state);
  // console.log(action);

  const { originalDeposit, editedLUSD, changePending } = state;

  switch (action.type) {
    case "startChange": {
      console.log("changeStarted");
      return { ...state, changePending: true };
    }

    case "finishChange":
      return { ...state, changePending: false };

    case "setDeposit":
      return { ...state, editedLUSD: Decimal.from(action.newValue) };

    case "revert":
      return { ...state, editedLUSD: originalDeposit.currentLUSD };

    case "updateStore": {
      const {
        stateChange: { stabilityDeposit: updatedDeposit },
      } = action;

      if (!updatedDeposit) {
        return state;
      }

      const newState = { ...state, originalDeposit: updatedDeposit };

      const changeCommitted =
        !updatedDeposit.initialLUSD.eq(originalDeposit.initialLUSD) ||
        updatedDeposit.currentLUSD.gt(originalDeposit.currentLUSD) ||
        updatedDeposit.collateralGain.lt(originalDeposit.collateralGain) ||
        updatedDeposit.lqtyReward.lt(originalDeposit.lqtyReward);

      if (changePending && changeCommitted) {
        return finishChange(revert(newState));
      }

      return {
        ...newState,
        editedLUSD: updatedDeposit.apply(
          originalDeposit.whatChanged(editedLUSD)
        ),
      };
    }
  }
};

export const BalanceManager: React.FC<SystemStatsProps> = ({
  variant = "info",
  showBalances,
}) => {
  const [
    { originalDeposit, editedLUSD, changePending },
    dispatch,
  ] = useLiquityReducer(reduce, init);
  const { account } = useLiquity();
  const {
    accountBalance,
    lusdBalance: realLusdBalance,
    lqtyBalance,
  } = useLiquitySelector(select);
  const { bLusdBalance, lusdBalance: customLusdBalance } = useBondView();
  const { LUSD_OVERRIDE_ADDRESS } = useBondAddresses();

  const lusdBalance =
    LUSD_OVERRIDE_ADDRESS === null ? realLusdBalance : customLusdBalance;

  return (
    <Card {...{ variant }}>
      <Flex
        sx={{
          alignItems: "center",
          gap: 2,
          pb: 3,
          borderBottom: 1,
          borderColor: "#263340",
        }}
      >
        <Image src={BalanceLogo} />
        <Heading as={"h2"} sx={{}}>
          Balance
        </Heading>
      </Flex>
      <Flex
        sx={{ justifyContent: "flex-start", gap: "70px", width: "70%", mt: 3 }}
      >
        {/* <Icon name="wallet" size="lg" /> */}

        {([
          ["BNB", accountBalance, "#F2BD0C"],
          [COIN, Decimal.from(lusdBalance || 0), "#895EFD"],
          [GT, Decimal.from(lqtyBalance), "#5B48D5"],
          // ["bLUSD", Decimal.from(bLusdBalance || 0)]
        ] as const).map(([currency, balance, color], i) => (
          <Flex key={i} sx={{ flexDirection: "column" }}>
            <Heading sx={{ fontSize: "20px" }}>{currency}</Heading>
            <Text sx={{ fontSize: "16px", color: color }}>
              {balance.prettify()}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Heading sx={{ fontSize: "16px", marginTop: "16px" }}>
        DCNX Rewards :{" "}
        <span
          style={{
            color: originalDeposit.lqtyReward.nonZero && "#28c081",
          }}
        >
          {!originalDeposit.isEmpty
            ? originalDeposit.lqtyReward.prettify()
            : "0.00"}
        </span>
        <span style={{ verticalAlign: "super" }}>
          <InfoIcon size="xs" tooltip={"Pending DCNX Rewards"} />{" "}
        </span>{" "}
      </Heading>
    </Card>
  );
};
