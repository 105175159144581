import React, { useCallback } from "react";
import { Card, Heading, Box, Button, Flex, Image } from "theme-ui";
import { CollateralSurplusAction } from "../CollateralSurplusAction";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { useTroveView } from "./context/TroveViewContext";
import { InfoMessage } from "../InfoMessage";
import MintLogo from "../../assets/mint-icon.svg";

const select = ({ collateralSurplusBalance }: LiquityStoreState) => ({
  hasSurplusCollateral: !collateralSurplusBalance.isZero,
});

export const RedeemedTrove: React.FC = () => {
  const { hasSurplusCollateral } = useLiquitySelector(select);
  const { dispatchEvent } = useTroveView();

  const handleOpenTrove = useCallback(() => {
    dispatchEvent("OPEN_TROVE_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Flex
        sx={{
          alignItems: "center",
          gap: 2,
          pb: 3,
          borderBottom: 1,
          borderColor: "#263340",
        }}
      >
        <Image src={MintLogo} />
        <Heading sx={{}}>Mint USDEFI</Heading>
      </Flex>
      <Box sx={{ py: [2, 3], px: [0, 0] }}>
        <InfoMessage title="Your Mint has been redeemed.">
          {hasSurplusCollateral
            ? "Please reclaim your remaining collateral before opening a new Mint."
            : "You can borrow USDEFI by opening a Mint."}
        </InfoMessage>

        <Flex variant="layout.actions">
          {hasSurplusCollateral && <CollateralSurplusAction />}
          {!hasSurplusCollateral && (
            <Button onClick={handleOpenTrove}>Open Mint</Button>
          )}
        </Flex>
      </Box>
    </Card>
  );
};
