import React, { useEffect, useState } from "react";
import { Heading, Box, Card, Button, Image, Flex, Text } from "theme-ui";

import {
  Decimal,
  Decimalish,
  StabilityDeposit,
  LiquityStoreState,
  Difference,
} from "@liquity/lib-base";
import StabilityLogo from "../../assets/stability-pool-icon.svg";
import StabilityBlue from "../../assets/stability-blue.svg";
import { useLiquitySelector } from "@liquity/lib-react";

import { COIN, GT } from "../../strings";

import { Icon } from "../Icon";
import { EditableRow, StaticRow } from "../Trove/Editor";
import { LoadingOverlay } from "../LoadingOverlay";
import { InfoIcon } from "../InfoIcon";
import Tabs from "../Tabs";
import { Yield } from "./Yield";
import { RemainingLQTY } from "./RemainingLQTY";
import { roundDown } from "../../utils/rounder";
import { useMyTransactionState } from "../Transaction";

const select = ({ lusdBalance, lusdInStabilityPool }: LiquityStoreState) => ({
  lusdBalance,
  lusdInStabilityPool,
});

type StabilityDepositEditorProps = {
  originalDeposit: StabilityDeposit;
  editedLUSD: Decimal;
  changePending: boolean;
  currentTab: string;
  tabs: string[];
  setCurrentTab: (value: string) => void;
  dispatch: (
    action: { type: "setDeposit"; newValue: Decimalish } | { type: "revert" }
  ) => void;
};

export const StabilityDepositEditor: React.FC<StabilityDepositEditorProps> = ({
  originalDeposit,
  editedLUSD,
  changePending,
  dispatch,
  children,
  tabs,
  currentTab,
  setCurrentTab,
}) => {
  const { lusdBalance, lusdInStabilityPool } = useLiquitySelector(select);
  const editingState = useState<string>();

  const edited = !editedLUSD.eq(originalDeposit.currentLUSD);

  const maxAmount =
    currentTab === tabs[0]
      ? roundDown(lusdBalance)
      : roundDown(originalDeposit.currentLUSD);
  const maxedOut =
    currentTab === tabs[0]
      ? roundDown(editedLUSD).eq(maxAmount)
      : editedLUSD.lt(Decimal.from("0.01"));
  const [enteredLUSD, setEnteredLUSD] = useState(Decimal.from(0));
  const lusdInStabilityPoolAfterChange = lusdInStabilityPool
    .sub(originalDeposit.currentLUSD)
    .add(editedLUSD);

  const originalPoolShare = originalDeposit.currentLUSD.mulDiv(
    100,
    lusdInStabilityPool
  );
  const newPoolShare = editedLUSD.mulDiv(100, lusdInStabilityPoolAfterChange);
  const poolShareChange =
    originalDeposit.currentLUSD.nonZero &&
    Difference.between(newPoolShare, originalPoolShare).nonZero;

  const transactionId = "stability-deposit";
  const transactionState = useMyTransactionState(transactionId);

  useEffect(() => {
    if (transactionState.type === "confirmedOneShot") {
      dispatch({ type: "revert" });
      setEnteredLUSD(Decimal.from(0));
    }
  }, [transactionState.type, dispatch]);

  useEffect(() => {
    dispatch({ type: "revert" });
    setEnteredLUSD(Decimal.from(0));
  }, [currentTab]);

  return (
    <Card>
      {/* <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          pb: 3,
          borderBottom: 1,
          borderColor: "#263340",
        }}
      >
        <Flex sx={{ alignItems: "center", gap: 2 }}>
          <Image src={StabilityLogo} />
          <Heading>Stability Pool</Heading>
        </Flex>
        
        {edited && !changePending && (
          <Button
            variant="titleIcon"
            sx={{ ":enabled:hover": { color: "danger" } }}
            onClick={() => {
              dispatch({ type: "revert" });
              setEnteredLUSD(Decimal.from(0));
            }}
          >
            <Icon name="history" size="lg" />
          </Button>
        )}
      </Flex> */}
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          pb: 3,
          borderBottom: 1,
          borderColor: "#263340",
        }}
      >
        <Flex sx={{ alignItems: "center", gap: 2 }}>
          <Image src={StabilityLogo} />
          <Heading sx={{}}>Stability Pool</Heading>
        </Flex>
        <Yield />

        <RemainingLQTY />
      </Flex>

      <Box sx={{ py: [2, 3], px: [0, 0] }}>
        <Flex
          sx={{
            justifyContent: "space-between",
            width: "100%",
            px: "38px",
            mb: "6px",
          }}
        >
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          <Flex sx={{ color: "#0C94C0", alignItems: "center", gap: 1 }}>
            <Image src={StabilityBlue} width={"15px"} height={"auto"} />
            <Text sx={{ fontSize: "14px" }}>
              {originalDeposit.currentLUSD.prettify()}
            </Text>
            {/* {([
              [COIN, Decimal.from(lusdBalance || 0), "#0C94C0"],
              // ["bLUSD", Decimal.from(bLusdBalance || 0)]
            ] as const).map(([currency, balance, color], i) => (
              <Text key={i} sx={{ fontSize: "14px", color: color }}>
                {balance.prettify()}
              </Text>
            ))} */}
          </Flex>
        </Flex>
        <EditableRow
          label={currentTab === tabs[0] ? "Deposit" : "Withdraw"}
          inputId="deposit-lqty"
          amount={enteredLUSD.prettify(2)}
          maxAmount={maxAmount.toString(2)}
          maxedOut={maxedOut}
          unit={COIN}
          {...{ editingState }}
          editedAmount={enteredLUSD.toString(2)}
          // setEditedAmount={newValue => dispatch({ type: "setDeposit", newValue })}
          setEditedAmount={(newValue) => {
            const decimalPlaces = newValue.split(".")[1]?.length || 0;
            if (decimalPlaces <= 2) {
              setEnteredLUSD(Decimal.from(newValue));

              dispatch({
                type: "setDeposit",
                newValue:
                  currentTab === tabs[0]
                    ? Decimal.from(newValue).add(originalDeposit.currentLUSD)
                    : Decimal.from(newValue).gte(originalDeposit.currentLUSD)
                    ? Decimal.from(0)
                    : originalDeposit.currentLUSD.sub(Decimal.from(newValue)),
              });
            } else {
              console.log("Cannot Enter More than 2 Decimals");
            }
          }}
        />

        {newPoolShare.infinite ? (
          <StaticRow label="Pool share" inputId="deposit-share" amount="N/A" />
        ) : (
          <StaticRow
            label="Pool share"
            inputId="deposit-share"
            amount={newPoolShare.prettify(2).concat("%")}
            pendingAmount={poolShareChange?.prettify(2).concat("%")}
            pendingColor={poolShareChange?.positive ? "success" : "danger"}
            unit="%"
          />
        )}

        {!originalDeposit.isEmpty && (
          <>
            <StaticRow
              label="Liquidation gain"
              inputId="deposit-gain"
              amount={originalDeposit.collateralGain.prettify(4).concat(" BNB")}
              color={originalDeposit.collateralGain.nonZero && "success"}
              unit="BNB"
            />

            <StaticRow
              label="Reward"
              inputId="deposit-reward"
              amount={originalDeposit.lqtyReward.prettify().concat(` ${GT}`)}
              color={originalDeposit.lqtyReward.nonZero && "success"}
              unit={GT}
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "240px" }}>
                      Although the DCNX rewards accrue every minute, the value
                      on the UI only updates when a user transacts with the
                      Stability Pool. Therefore you may receive more rewards
                      than is displayed when you claim or adjust your deposit.
                    </Card>
                  }
                />
              }
            />
          </>
        )}
        {children}
      </Box>

      {changePending && <LoadingOverlay />}
    </Card>
  );
};
