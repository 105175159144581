import { Card, Heading, Box, Flex, Button, Image, Link } from "theme-ui";

import { GT } from "../../strings";

import { InfoMessage } from "../InfoMessage";
import { useStakingView } from "./context/StakingViewContext";
import StakingLogo from "../../assets/staking-icon.svg";

export const NoStake: React.FC = () => {
  const { dispatch } = useStakingView();

  return (
    <Card>
      <Flex
        sx={{
          alignItems: "center",
          gap: 2,
          pb: 3,
          borderBottom: 1,
          borderColor: "#263340",
        }}
      >
        <Image src={StakingLogo} />
        <Heading sx={{}}>DCNX Staking</Heading>
      </Flex>
      <Box sx={{ py: [2, 3], px: [0, 0] }}>
        <InfoMessage title={`You haven't staked ${GT} yet.`}>
          Stake {GT} to earn a share of protocol fees in BNB and USDEFI.
          <br /> Learn more:{" "}
          <Link
            sx={{ color: "text", textDecoration: "underline" }}
            href="https://dcntrl-network.gitbook.io/docs_en/user-guides/how-to-stake-dcnx-to-earn-protocol-revenue"
            target="_blank"
            rel="noopener noreferrer"
          >
            How does Staking work?
          </Link>
        </InfoMessage>

        <Flex variant="layout.actions">
          <Button onClick={() => dispatch({ type: "startAdjusting" })}>
            Start staking
          </Button>
        </Flex>
      </Box>
    </Card>
  );
};
