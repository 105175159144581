import React, { useEffect, useState } from "react";
import { Button, Flex, Image, Text } from "theme-ui";
import { Token } from "./Token";
import CashRegister from "../assets/cashregister.svg";
import Info from "../assets/info.svg";
import Exchange from "../assets/exchange.svg";

type Props = {};

interface Token {
  symbol: string;
}

interface CoinPrices {
  [key: string]: { usd: number };
}

const tokenData = [
  {
    symbol: "bnb",
    cmc_name: "binancecoin",
    gecko_link: "https://www.coingecko.com/en/coins/bnb",
  },
  {
    symbol: "usdefi",
    cmc_name: "usdefi",
    gecko_link:
      "https://coinmarketcap.com/dexscan/bsc/0x4e4df766dce0c3dc8e05bb3da5e071b5cf87b9f6/",
    explorer_link:
      "https://bscscan.com/token/0x480315cbaa3b4fa8e48afc7e43b821a45bb6d202",
    swap_link:
      "https://pancakeswap.finance/swap?outputCurrency=0x480315cBaA3B4fa8e48aFc7E43b821a45BB6d202",
  },
  {
    symbol: "dcnx",
    cmc_name: "dcntrl-network",
    gecko_link: "https://www.coingecko.com/en/coins/dcntrl-network",
    explorer_link:
      "https://bscscan.com/token/0xf106f153bd77be6d5191a07159c99c4219a1cec4",
    swap_link:
      "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xf106f153bd77be6d5191a07159c99c4219a1cec4",
  },
];

function TokenStatsBar({}: Props) {
  const [coinPrices, setCoinPrices] = useState<CoinPrices>({});
  const [coinPrices2, setCoinPrices2] = useState<CoinPrices>({});

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchCoinPrices();
    return () => {
      isMounted = false;
    };
  }, []);

  const fetchCoinPrices = async () => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin%2Cliquity%2Cdcntrl-network&vs_currencies=usd"
      );
      const data = await response.json();
      setCoinPrices(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Flex sx={{ justifyContent: "space-around", mt: "57px" }}>
      {tokenData.map((item, i) => (
        <Flex
          key={i}
          sx={{ flexDirection: "column", alignItems: "center", gap: "5px" }}
        >
          <Token tokenName={item.symbol} />
          <Text sx={{ fontSize: "20px", fontWeight: 700, color: "#FFFFFF" }}>
            {item.symbol.toUpperCase()}
          </Text>
          {/* {coinPrices.binancecoin
            ? coinPrices.binancecoin.usd
              ? coinPrices.binancecoin.usd
              : "no"
            : "no"} */}
          <Text sx={{ fontSize: "16px", fontWeight: 500, color: "#FFFFFF" }}>
            {item.symbol === "usdefi"
              ? "1.00"
              : item.symbol === "dcnx"
              ? coinPrices[item.cmc_name]
                ? coinPrices[item.cmc_name].usd.toFixed(4)
                : "-"
              : coinPrices[item.cmc_name]
              ? coinPrices[item.cmc_name].usd.toFixed(2)
              : "-"}
            {/* {coinPrices.binancecoin
              ? item.symbol === "bnb"
                ? coinPrices["binancecoin"].usd
                : item.symbol === "usdefi"
                ? coinPrices["liquity"].usd
                : coinPrices["liquity-usd"].usd
              : // ? coinPrices["liquity"]["usd"]
                // : coinPrices["liquity-us"]["usd"]
                "-"} */}
          </Text>
          <Text sx={{ fontSize: "10px", fontWeight: 400, color: "#FFFFFF" }}>
            Market Price
          </Text>
          <Flex sx={{ mt: 0 }}>
            {item.gecko_link ? (
              <Button
                variant="icon"
                sx={{ width: "20px", height: "20px" }}
                onClick={() => {
                  window.open(item.gecko_link, "noopener norefered");
                }}
              >
                <Image src={Info} />
              </Button>
            ) : null}
            {item.explorer_link ? (
              <Button
                variant="icon"
                sx={{ width: "20px", height: "20px" }}
                onClick={() => {
                  window.open(item.explorer_link, "noopener norefered");
                }}
              >
                <Image src={CashRegister} />
              </Button>
            ) : null}
            {item.swap_link ? (
              <Button
                variant="icon"
                sx={{ width: "20px", height: "20px" }}
                onClick={() => {
                  window.open(item.swap_link, "noopener norefered");
                }}
              >
                <Image src={Exchange} />
              </Button>
            ) : null}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
}

export default TokenStatsBar;
