import React from "react";
import { Card, Heading, Link, Box, Text, Flex, Avatar, Image } from "theme-ui";
import { AddressZero } from "@ethersproject/constants";
import { Decimal, Percent, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import StatsLogo from "../assets/liquidity-icon.svg";
import { useLiquity } from "../hooks/LiquityContext";
import { Statistic } from "./Statistic";
import * as l from "../lexicon";
import { Token } from "./Token";
import TokenStatsBar from "./TokenStatsBar";

const selectBalances = ({
  accountBalance,
  lusdBalance,
  lqtyBalance,
}: LiquityStoreState) => ({
  accountBalance,
  lusdBalance,
  lqtyBalance,
});

const Balances: React.FC = () => {
  const { accountBalance, lusdBalance, lqtyBalance } = useLiquitySelector(
    selectBalances
  );

  return (
    <Box sx={{ mb: 3 }}>
      <Heading>My Account Balances</Heading>
      <Statistic lexicon={l.ETH}>{accountBalance.prettify(4)}</Statistic>
      <Statistic lexicon={l.LUSD}>{lusdBalance.prettify()}</Statistic>
      <Statistic lexicon={l.LQTY}>{lqtyBalance.prettify()}</Statistic>
    </Box>
  );
};

const GitHubCommit: React.FC<{ children?: string }> = ({ children }) =>
  children?.match(/[0-9a-f]{40}/) ? (
    <Link href={`https://github.com/liquity/dev/commit/${children}`}>
      {children.substr(0, 7)}
    </Link>
  ) : (
    <>unknown</>
  );

type SystemStatsProps = {
  variant?: string;
  showBalances?: boolean;
};

const select = ({
  numberOfTroves,
  price,
  total,
  lusdInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLQTY,
  frontend,
}: LiquityStoreState) => ({
  numberOfTroves,
  price,
  total,
  lusdInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLQTY,
  kickbackRate: frontend.status === "registered" ? frontend.kickbackRate : null,
});

export const LiquidityStats: React.FC<SystemStatsProps> = ({
  variant = "info",
  showBalances,
}) => {
  const {
    liquity: {
      connection: { version: contractsVersion, deploymentDate, frontendTag },
    },
  } = useLiquity();

  const {
    numberOfTroves,
    price,
    lusdInStabilityPool,
    total,
    borrowingRate,
    totalStakedLQTY,
    kickbackRate,
  } = useLiquitySelector(select);

  const lusdInStabilityPoolPct =
    total.debt.nonZero && new Percent(lusdInStabilityPool.div(total.debt));
  const totalCollateralRatioPct = new Percent(total.collateralRatio(price));
  const borrowingFeePct = new Percent(borrowingRate);
  const kickbackRatePct =
    frontendTag === AddressZero ? "100" : kickbackRate?.mul(100).prettify();

  return (
    <Card {...{ variant }}>
      {showBalances && <Balances />}
      <Flex
        sx={{
          alignItems: "center",
          gap: 2,
          pb: 3,
          borderBottom: 1,
          borderColor: "#263340",
        }}
      >
        <Image src={StatsLogo} />
        <Heading sx={{}}>Liquidity</Heading>
      </Flex>

      {/* <Token tokenName='bnb'/> */}
      {/* <TokenStatsBar /> */}

      {/* <Heading
        as="h2"
        sx={{ mt: "57px", fontWeight: "body", fontSize: "20px" }}
      >
        Protocol
      </Heading> */}
      <Flex
        sx={{
          flexDirection: ["column", null, null, "row"],
          justifyContent: "space-between",
          alignItems: ["flex-start", null, null, "center"],
          my: 3,
        }}
      >
        <Flex sx={{ flexDirection: "column" }}>
          <Heading sx={{ fontSize: "20px" }}>TVL BNB</Heading>
          <Text sx={{ fontSize: "16px", color: "#F2BD0C" }}>
            {total.collateral.shorten()} &nbsp;($
            {Decimal.from(total.collateral.mul(price)).shorten()})
          </Text>
        </Flex>
        <Flex sx={{ flexDirection: "column" }}>
          <Heading sx={{ fontSize: "20px" }}>Borrowing Fee</Heading>
          <Text sx={{ fontSize: "16px", color: "#30B27F" }}>
            {borrowingFeePct.toString(2)}
          </Text>
        </Flex>
        <Flex sx={{ flexDirection: "column" }}>
          <Heading sx={{ fontSize: "20px" }}>Collateral Ratio</Heading>
          <Text sx={{ fontSize: "16px", color: "#30B27F" }}>
            {totalCollateralRatioPct.prettify()}
          </Text>
        </Flex>
      </Flex>

      <Flex sx={{ flexDirection: "row", gap: 2 }}>
        <Heading sx={{ fontSize: "16px" }}>
          Recovery Mode :{" "}
          <span
            style={{
              color: total.collateralRatioIsBelowCritical(price)
                ? "#dc2c10"
                : "#30B27F",
            }}
          >
            {total.collateralRatioIsBelowCritical(price) ? "Yes" : "No"}
          </span>
        </Heading>
      </Flex>
    </Card>
  );
};
