import { Box, Flex, Heading, Image, Text } from "theme-ui";

import { Icon } from "./Icon";
import InfoLogo from '../assets/info-icon.svg'

type InfoMessageProps = {
  title: string;
  icon?: React.ReactNode;
};

export const InfoMessage: React.FC<InfoMessageProps> = ({ title, children, icon }) => (
  <Box sx={{ mx: 0, mb: 3 }}>
    <Flex sx={{ alignItems: "center", mb: "10px" }}>
      
        <Image src={InfoLogo} sx={{mr:'12px',height:'20px',width:'20px'}} />
      
      

      <Heading as="h3">{title}</Heading>
    </Flex>

    <Text sx={{ fontSize: 2 }}>{children}</Text>
  </Box>
);
