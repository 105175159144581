import React from "react";
import { Card, Heading, Link, Box, Text, Flex, Avatar, Image } from "theme-ui";
import { AddressZero } from "@ethersproject/constants";
import { Decimal, Percent, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import StatsLogo from '../assets/statistics-icon.svg'
import { useLiquity } from "../hooks/LiquityContext";
import { Statistic } from "./Statistic";
import * as l from "../lexicon";
import { Token } from "./Token";
import TokenStatsBar from "./TokenStatsBar";

const selectBalances = ({ accountBalance, lusdBalance, lqtyBalance }: LiquityStoreState) => ({
  accountBalance,
  lusdBalance,
  lqtyBalance
});

const Balances: React.FC = () => {
  const { accountBalance, lusdBalance, lqtyBalance } = useLiquitySelector(selectBalances);

  return (
    <Box sx={{ mb: 3 }}>
      <Heading>My Account Balances</Heading>
      <Statistic lexicon={l.ETH}>{accountBalance.prettify(4)}</Statistic>
      <Statistic lexicon={l.LUSD}>{lusdBalance.prettify()}</Statistic>
      <Statistic lexicon={l.LQTY}>{lqtyBalance.prettify()}</Statistic>
    </Box>
  );
};

const GitHubCommit: React.FC<{ children?: string }> = ({ children }) =>
  children?.match(/[0-9a-f]{40}/) ? (
    <Link href={`https://github.com/liquity/dev/commit/${children}`}>{children.substr(0, 7)}</Link>
  ) : (
    <>unknown</>
  );

type SystemStatsProps = {
  variant?: string;
  showBalances?: boolean;
};

const select = ({
  numberOfTroves,
  price,
  total,
  lusdInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLQTY,
  frontend
}: LiquityStoreState) => ({
  numberOfTroves,
  price,
  total,
  lusdInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLQTY,
  kickbackRate: frontend.status === "registered" ? frontend.kickbackRate : null
});

export const SystemStats: React.FC<SystemStatsProps> = ({ variant = "info", showBalances }) => {
  const {
    liquity: {
      connection: { version: contractsVersion, deploymentDate, frontendTag }
    }
  } = useLiquity();

  const {
    numberOfTroves,
    price,
    lusdInStabilityPool,
    total,
    borrowingRate,
    totalStakedLQTY,
    kickbackRate
  } = useLiquitySelector(select);

  const lusdInStabilityPoolPct =
    total.debt.nonZero && new Percent(lusdInStabilityPool.div(total.debt));
  const totalCollateralRatioPct = new Percent(total.collateralRatio(price));
  const borrowingFeePct = new Percent(borrowingRate);
  const kickbackRatePct = frontendTag === AddressZero ? "100" : kickbackRate?.mul(100).prettify();

  return (
    <Card {...{ variant }} >
      {showBalances && <Balances />}
      <Flex sx={{alignItems:'center',gap:2,pb:3, borderBottom: 1, borderColor: "#263340"}}>
        <Image src={StatsLogo} />
      <Heading
       
        sx={{ }}
      >Statistics</Heading></Flex>

      {/* <Token tokenName='bnb'/> */}
      <TokenStatsBar />

      <Heading as="h2" sx={{ mt: '57px', fontWeight: "body",fontSize:'20px' }}>
        Protocol
      </Heading>
      <Flex sx={{flexDirection:'column',gap:2 ,mt:3}}>
      <Statistic lexicon={l.BORROW_FEE}>{borrowingFeePct.toString(2)}</Statistic>

<Statistic lexicon={l.TVL}>
  {total.collateral.shorten()} <Text sx={{ fontSize: 1 }}>&nbsp;BNB</Text>
  <Text sx={{ fontSize: 1 }}>
    &nbsp;(${Decimal.from(total.collateral.mul(price)).shorten()})
  </Text>
</Statistic>
<Statistic lexicon={l.TROVES}>{Decimal.from(numberOfTroves).prettify(0)}</Statistic>
<Statistic lexicon={l.LUSD_SUPPLY}>{total.debt.shorten()}</Statistic>
{lusdInStabilityPoolPct && (
  <Statistic lexicon={l.STABILITY_POOL_LUSD}>
    {lusdInStabilityPool.shorten()}
    <Text sx={{ fontSize: 1 }}>&nbsp;({lusdInStabilityPoolPct.toString(1)})</Text>
  </Statistic>
)}
<Statistic lexicon={l.STAKED_LQTY}>{totalStakedLQTY.shorten()}</Statistic>
<Statistic lexicon={l.TCR}>{totalCollateralRatioPct.prettify()}</Statistic>
<Statistic lexicon={l.RECOVERY_MODE}>
  {total.collateralRatioIsBelowCritical(price) ? <Box color="danger">Yes</Box> : "No"}
</Statistic>
{}

{/* <Heading as="h2" sx={{ mt: 3, fontWeight: "body",fontSize:'20px' }}>
  Frontend
</Heading> */}
{/* {kickbackRatePct && <Statistic lexicon={l.KICKBACK_RATE}>{kickbackRatePct}%</Statistic>} */}

    </Flex>
      
      {/* <Box sx={{ mt: 3, opacity: 0.66 }}>
        <Box sx={{ fontSize: 0 }}>
          Contracts version: <GitHubCommit>{contractsVersion}</GitHubCommit>
        </Box>
        <Box sx={{ fontSize: 0 }}>Deployed: {deploymentDate.toLocaleString()}</Box>
        <Box sx={{ fontSize: 0 }}>
          Frontend version:{" "}
          {process.env.NODE_ENV === "development" ? (
            "development"
          ) : (
            <GitHubCommit>{process.env.REACT_APP_VERSION}</GitHubCommit>
          )}
        </Box>
      </Box> */}
    </Card>
  );
};
